import React from "react";
import "./About.css";
import myvideo from "./myvideo.mkv";
import hassan from "./document/hassan.jfif";
import khalid from "./document/khalid.jfif";
function About() {
  return (
    <div className="about">
      <h1>about us</h1>

      <div className="cards__container">
        <div className="about__card">
          <img src={khalid} alt="" />
          <div className="about__card__right">
            <h3>Khalid Toustous</h3>
            <div>English Teacher</div>
            <p>
              Hello, my name is Khalid Toustous. I am 26 years old and currently
              a university student studying English. Over the past three years,
              I have been teaching English, and I have also author second
              baccalaureate students.
            </p>
          </div>
        </div>
        <div className="about__card">
          <img src={hassan} alt="" />
          <div className="about__card__right">
            <h3>Hassan El Amri</h3>
            <div>Front End Web Developer</div>
            <p>
              I'm a Front End Web Developer with over three years of
              experience in building Static and responsive websites. Proficient
              in HTML, CSS, JavaScript, React.js and Next.js to create seamless
              user experiences and efficient front-end solutions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
