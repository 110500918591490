import React, { useState } from "react";
import "./Plan.css";
import toustous from "./toustous.jpg";
import SchoolIcon from "@mui/icons-material/School";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Link } from "react-router-dom";

function Plan() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, phone } = formData;

    const message = `Name: ${name}%0AEmail: ${email}%0APhone: ${phone}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=212690978812&text=${message}`;
    window.open(whatsappUrl, '_blank');
    setFormData({ name: '', email: '', phone: '' });
  };
 

  return (
    <div className="plan" >
      <div className="sections__title">Choose Your Plan</div>
      <div className="plans__container">
        <div className="plan__content">
          <img src={toustous} alt="" />
          <div className="plan__title">DIGITAL BOOK</div>
          <div className="icon__text__container">
            <span className="icon__text">
              <AttachMoneyIcon className="plan__icon" />
              <span>49dh</span>
            </span>
            <span className="icon__text">
              <SchoolIcon className="plan__icon" />
              <span>30 learners</span>
            </span>
          </div>
          <Link to="/about">
            <button className="buy__button">Buy</button>
          </Link>
          <Link to="/about">
            <button className="openBook">Open The Book</button>
          </Link>
        </div>
        <div className="plan__content">
          <img src={toustous} alt="" />
          <div className="plan__title">DIGITAL BOOK</div>
          <div className="icon__text__container">
            <span className="icon__text">
              <AttachMoneyIcon className="plan__icon" />
              <span>49dh</span>
            </span>
            <span className="icon__text">
              <SchoolIcon className="plan__icon" />
              <span>30 learners</span>
            </span>
          </div>
          <Link to="/about">
            <button className="buy__button">Buy</button>
          </Link>
          <Link to="/about">
            <button className="openBook">Open The Book</button>
          </Link>
        </div>
        <div className="plan__content">
          <img src={toustous} alt="" />
          <div className="plan__title">DIGITAL BOOK</div>
          <div className="icon__text__container">
            <span className="icon__text">
              <AttachMoneyIcon className="plan__icon" />
              <span>49dh</span>
            </span>
            <span className="icon__text">
              <SchoolIcon className="plan__icon" />
              <span>30 learners</span>
            </span>
          </div>
          <Link to="/about">
            <button className="buy__button">Buy</button>
          </Link>
          <Link to="/about">
            <button className="openBook">Open The Book</button>
          </Link>
        </div>
      </div>
      <div className="form" >
        <div>Fill in the form and join us now</div>
        <form onSubmit={handleSubmit}>
      <div>
        <input type="text" placeholder="Name" name="name" value={formData.name} onChange={handleChange} required />
      </div>
      <div>
        <input type="email" placeholder="Email" name="email" value={formData.email} onChange={handleChange} required />
      </div>
      <div>
        <input type="tel" placeholder="Phone" name="phone" value={formData.phone} onChange={handleChange} required />
      </div>
      <button type="submit">Submit</button>
    </form>
      </div>
    </div>
  );
}

export default Plan;
