import React, { useEffect, useState } from "react";
import Header from "./Header";
import logo from "./hassan-documents/white-logo.png";
import bluelogo from "./hassan-documents/bluelogo.png";
import { Link } from "react-router-dom";
import './BookLogin.css'
function BookLogin() {
    useEffect(() => {
        // Fetching data from API
        // fetch('https://jsonplaceholder.typicode.com/posts')
        //  .then(response => response.json())
        //  .then(data => console.log(data))
        //  .catch(error => console.error('Error:', error))
        //  .finally(() => console.log('Fetch complete'))
    
        localStorage.setItem("username", "toustous");
        localStorage.setItem("password", "123");
      }, []);
    
    
      const [username, setUsername] = useState("");
      const [password, setPassword] = useState("");
      const handleLogin = (e) => {
        e.preventDefault();
        if (
          username === localStorage.getItem("username") &&
          password === localStorage.getItem("password")
        ) {
          // localStorage.setItem("isLoggedIn", true);
          window.location.href = "/AllCourses";
        }
        if (username !== localStorage.getItem("username") && username !== "") {
          document.querySelector(".username").style.display = "block";
          document.querySelector(".username").textContent = "username is incorrect";
        }
        if (password !== localStorage.getItem("password") && password !== "") {
          document.querySelector(".password").style.display = "block";
          document.querySelector(".password").textContent = "password is incorrect";
        }
    
        if (username === "") {
          document.querySelector(".username").style.display = "block";
          document.querySelector(".username").textContent = "username is required";
        }
        if (password === "") {
          document.querySelector(".password").style.display = "block";
          document.querySelector(".password").textContent = "password is required";
        }
    
        setUsername("");
        setPassword("");
      };
  return <div className="bookLogin">
     <Header />
      <div className="bookLogin__form">
        <p>
          We're here to help you learn English and pass your baccalaureate exam.
          If you have any questions, need help, or want to give us
        </p>
        <img src={bluelogo} alt="" />
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <p className="username">username is required</p>
        <input
          type="text"
          placeholder="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <p className="password">password is required</p>
        <button onClick={handleLogin}>Login</button>
      </div>

  </div>;
}

export default BookLogin;





