import React, { useEffect } from "react";
import "./Header.css";
import logo from "./hassan-documents/white-logo.png";
import { Link } from "react-router-dom";

function Header() {
  window.onscroll = function () {
    const header = document.getElementById("header");
    const headerLogo = document.querySelector("#header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance
      header.style.background = "#253E56";
      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "110px";
        header.style.background = "";
      }
    }
  };
  useEffect(() => {
    const header = document.getElementById("header");
    const headerLogo = document.querySelector("#header img");
    if (window.location.href === "http://localhost:3000/book") {
      header.style.background = "#252B34";
      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    }
  }, []);
 function scrollToTop(){
    window.scrollTo({top: 0, behavior: "smooth"});
  }
  return (
    <div>
      <header id="header">
        <img src={logo} alt="" />
        <ul>
          <a href="" onClick={scrollToTop}>
            <li>Home</li>
          </a>

          <a href="#about">
            <li>About</li>
          </a>
          <a href="#feedback">
            <li>Feedback</li>
          </a>

          {/* <li>Log in</li> */}
        </ul>
      </header>
    </div>
  );
}

export default Header;
