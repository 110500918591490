import React, { useEffect } from "react";
import Header from "./Header";
import "./AllCourses.css";
import logo from "./hassan-documents/bluelogo.png";
import thumbnail from "./hassan-documents/BG.jpg";
import allCourses1 from "./mahawir/VOCABULARY.jpg";
import exercises from './UNIT1Images/exercises.jpg'
import GIFTSOFYOUTH from './UNIT1Images/GIFTSOFYOUTH.jpg'
import INTRODUCTIONTOVOCABULARY from './UNIT1Images/INTRODUCTIONTOVOCABULARY.jpg'
import Footer from "./Footer";
import { Link } from "react-router-dom";
function AllCourses() {
  const moreInfo = () => {
    alert("This course is still in development, please check back later.");
  };

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  // window.onscroll = function () {
  //   const header = document.getElementById("header");
  //   const headerLogo = document.querySelector("#header img");

  //   if (window.scrollY > 50) {
  //     // Change 30 to your desired scroll distance

  //     headerLogo.style.width = "60px";
  //     header.style.padding = "10px 100px";
  //     header.style.transition = "background 0.3s ease-in-out";
  //   } else {
  //     if (window.innerWidth < "668") {
  //       headerLogo.style.width = "60px";
  //       header.style.background = "";
  //       header.style.padding = "20px 100px";
  //     } else {
  //       headerLogo.style.width = "110px";
  //       header.style.background = "";
  //     }
  //   }
  // };
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  document.body.style.background = "#f1f1f1"
  return (
    <div className="allCourses">
      <header id="header">
        <img src={logo} alt="" />
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>
      <div className="courses__container">
        <div className="free__course">
          <h2>Vocabulary Lessons</h2>
          <p className="introduction__p">
            قبل أن تبدأ في مشاهدة شرح الدرس، من المهم جدًا أن تشاهد التقديم
            أولاً. التقديم سيساعدك على فهم الأفكار الأساسية والمفاهيم العامة
            التي ستسهل عليك متابعة الشرح بطريقة أكثر فعالية. باتباع هذا الترتيب،
            ستضمن فهمًا أعمق وأسهل للدرس وتحقيق أفضل النتائج.
          </p>

          <div className="img__title__container">
            <div className="course__img__title">
              <img src={INTRODUCTIONTOVOCABULARY} alt="" />
              <div className="courseTitle">Introduction to Vocabulary</div>
              <p className="arabic__p">مقدمة في دروس المفردات</p>
              <div className="vocabulary__buttons">
                <button className="moreInfo" onClick={moreInfo}>
                  More info
                </button>
                <Link to="/free/introduction">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={GIFTSOFYOUTH} alt="" />
              <div className="courseTitle">Unit 1 : Gifts of youth</div>
              <p className="arabic__p">الوحدة 1 : مواهب الشباب </p>
              <div className="vocabulary__buttons">
                <button className="moreInfo">More info</button>
                <Link to="/free/vocabulary">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={exercises} alt="" />
              <div className="courseTitle">Practice some exercises</div>
              <p className="arabic__p">ممارسة بعض التمارين</p>
              <div className="vocabulary__buttons">
                <div class="toast" id="toast">
                  This is a toast notification!
                </div>
                <button className="moreInfo">More info</button>
                <Link to="/free/vocabulary">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="free__course">
          <h2>Functions Lessons</h2>
          {/* <p >
            قبل أن تبدأ في مشاهدة شرح الدرس، من المهم جدًا أن تشاهد التقديم
            أولاً. التقديم سيساعدك على فهم الأفكار الأساسية والمفاهيم العامة
            التي ستسهل عليك متابعة الشرح بطريقة أكثر فعالية. باتباع هذا الترتيب،
            ستضمن فهمًا أعمق وأسهل للدرس وتحقيق أفضل النتائج.
          </p> */}

          <div className="img__title__container">
            <div className="course__img__title">
              <img src={INTRODUCTIONTOVOCABULARY} alt="" />
              <div className="courseTitle">Introduction to Vocabulary</div>
              <p className="arabic__p">مقدمة في دروس المفردات</p>
              <div className="vocabulary__buttons">
                <button className="moreInfo" onClick={moreInfo}>
                  More info
                </button>
                <Link to="/free/introduction">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={GIFTSOFYOUTH} alt="" />
              <div className="courseTitle">Unit 1 : Gifts of youth</div>
              <p className="arabic__p">الوحدة 1 : مواهب الشباب </p>
              <div className="vocabulary__buttons">
                <button className="moreInfo">More info</button>
                <Link to="/free/vocabulary">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={exercises} alt="" />
              <div className="courseTitle">Practice some exercises</div>
              <p className="arabic__p">ممارسة بعض التمارين</p>
              <div className="vocabulary__buttons">
                <div class="toast" id="toast">
                  This is a toast notification!
                </div>
                <button className="moreInfo">More info</button>
                <Link to="/free/vocabulary">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="free__course">
          <h2>Vocabulary Lessons</h2>
          {/* <p >
            قبل أن تبدأ في مشاهدة شرح الدرس، من المهم جدًا أن تشاهد التقديم
            أولاً. التقديم سيساعدك على فهم الأفكار الأساسية والمفاهيم العامة
            التي ستسهل عليك متابعة الشرح بطريقة أكثر فعالية. باتباع هذا الترتيب،
            ستضمن فهمًا أعمق وأسهل للدرس وتحقيق أفضل النتائج.
          </p> */}

          <div className="img__title__container">
            <div className="course__img__title">
              <img src={INTRODUCTIONTOVOCABULARY} alt="" />
              <div className="courseTitle">Introduction to Vocabulary</div>
              <p className="arabic__p">مقدمة في دروس المفردات</p>
              <div className="vocabulary__buttons">
                <button className="moreInfo" onClick={moreInfo}>
                  More info
                </button>
                <Link to="/free/introduction">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={GIFTSOFYOUTH} alt="" />
              <div className="courseTitle">Unit 1 : Gifts of youth</div>
              <p className="arabic__p">الوحدة 1 : مواهب الشباب </p>
              <div className="vocabulary__buttons">
                <button className="moreInfo">More info</button>
                <Link to="/free/vocabulary">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={exercises} alt="" />
              <div className="courseTitle">Practice some exercises</div>
              <p className="arabic__p">ممارسة بعض التمارين</p>
              <div className="vocabulary__buttons">
                <div class="toast" id="toast">
                  This is a toast notification!
                </div>
                <button className="moreInfo">More info</button>
                <Link to="/free/vocabulary">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AllCourses;
