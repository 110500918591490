import React from "react";
import "./Courses.css";
import toustous from "./toustous.jpg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlayLessonIcon from "@mui/icons-material/PlayLesson";
import PersonIcon from "@mui/icons-material/Person";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import tenses from "./ma7awir/tenses.jpg";
import functions from "./ma7awir/functions.jpg";
import grammar from "./ma7awir/grammar.jpg";
import vocabulary from "./ma7awir/VOCABULARY.jpg";
import writing from "./ma7awir/WRITING.jpg";
import startFreeThumbnail from './startFreeThumbnail.jpeg'
import ENGLISHTENSES2 from "./motivation/ENGLISHTENSES2.jpg"
import WRITING2 from "./motivation/WRITING2.jpg"
import nationalexams from "./motivation/nationalexams.jpg"
import VOCABULARY from "./motivation/VOCABULARY.jpg"
import book1 from './book1.jpeg'
import book2 from './book2.jpeg'
import book3 from './book3.jpeg'

function Courses() {
  return (
    <div className="courses">
      <h1>What will you get</h1>
      {/* <div className="categories">
        <h3>Popular Courses</h3>
        <ul>
          <li>All courses</li>

          <li>Functions</li>
          <li>Grammar</li>
          <li>Vocabulary</li>
          <li>Tenses</li>
          <select name="" id="">
            <option value="">More</option>
            <option value="">Writing</option>
            <option value="">Phrasal Verbs</option>
          </select>
        </ul>
      </div> */}

      <div className="courses__list">
        <div className="course">
          {/* <LockIcon className="lockIcon"/> */}
          <img src={startFreeThumbnail} alt="" className="startFreeImg"/>
          <div className="course__title freeTrialTitle">Unit 1 : Gifts Of Youth</div>
          <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>Lessons : 10</span>
            </div>

            <div className="icon__label">
              <PersonIcon className="icon" />
              <span>Students : +10</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>1 hour 30 min</span>
            </div>
          </div>
          <div className="button__whatsapp">
            <Link to="/AllCourses">
              <button className="freeAccess">Start Free</button>
            </Link>
            <WhatsAppIcon className="whatsappIcon" />
          </div>
        </div>
        <div className="course">
          
          <img src={ENGLISHTENSES2} alt="" />
          <div className="course__title__lock">
            
          <span className="course__title">Tenses Lessons</span>
          <LockIcon className="lockIcon" />
          </div>
          <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>Lessons : 10</span>
            </div>

            <div className="icon__label">
              <PersonIcon className="icon" />
              <span>Students : +10</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>1 hour 30 min</span>
            </div>
          </div>
          <div className="button__whatsapp">
            <Link to="/login">
              <button>Get Started</button>
            </Link>
            <WhatsAppIcon className="whatsappIcon" />
          </div>
        </div>
        <div className="course">
          
          <img src={functions} alt="" />
          <div className="course__title__lock">
            
          <span className="course__title">Functions Lessons</span>
          <LockIcon className="lockIcon" />
          </div>
          <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>Lessons : 10</span>
            </div>

            <div className="icon__label">
              <PersonIcon className="icon" />
              <span>Students : +10</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>1 hour 30 min</span>
            </div>
          </div>
          <div className="button__whatsapp">
            <Link to="/login">
              <button>Get Started</button>
            </Link>
            <WhatsAppIcon className="whatsappIcon" />
          </div>
        </div>
        <div className="course">
          
          <img src={grammar} alt="" />
          <div className="course__title__lock">
            
          <span className="course__title">Grammar Lessons</span>
          <LockIcon className="lockIcon" />
          </div>
          <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>Lessons : 10</span>
            </div>

            <div className="icon__label">
              <PersonIcon className="icon" />
              <span>Students : +10</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>1 hour 30 min</span>
            </div>
          </div>
          <div className="button__whatsapp">
            <Link to="/login">
              <button>Get Started</button>
            </Link>
            <WhatsAppIcon className="whatsappIcon" />
          </div>
        </div>
        <div className="course">
          
          <img src={VOCABULARY} alt="" />
          <div className="course__title__lock">
            
          <span className="course__title">Vocabulary Lessons</span>
          <LockIcon className="lockIcon" />
          </div>
          <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>Lessons : 10</span>
            </div>

            <div className="icon__label">
              <PersonIcon className="icon" />
              <span>Students : +10</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>1 hour 30 min</span>
            </div>
          </div>
          <div className="button__whatsapp">
            <Link to="/login">
              <button>Get Started</button>
            </Link>
            <WhatsAppIcon className="whatsappIcon" />
          </div>
        </div>
        <div className="course">
          
          <img src={WRITING2} alt="" />
          <div className="course__title__lock">
            
          <span className="course__title">Writing Lessons</span>
          <LockIcon className="lockIcon" />
          </div>
          <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>Lessons : 10</span>
            </div>

            <div className="icon__label">
              <PersonIcon className="icon" />
              <span>Students : +10</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>1 hour 30 min</span>
            </div>
          </div>
          <div className="button__whatsapp">
            <Link to="/login">
              <button>Get Started</button>
            </Link>
            <WhatsAppIcon className="whatsappIcon" />
          </div>
        </div>
        <div className="course">
          {/* <LockIcon className="lockIcon"/> */}
          <img src={nationalexams} alt="" />
          <div className="course__title freeTrialTitle">National Exams</div>
          <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>Lessons : 10</span>
            </div>

            <div className="icon__label">
              <PersonIcon className="icon" />
              <span>Students : +10</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>1 hour 30 min</span>
            </div>
          </div>
          <div className="button__whatsapp">
            <Link to="/login">
              <button>Get Started</button>
            </Link>
            <WhatsAppIcon className="whatsappIcon" />
          </div>
        </div>
        <div className="course">
          
          <img src={book2} alt="" />
          <div className="course__title__lock">
            
          <span className="course__title">Deep but short book</span>
          <LockIcon className="lockIcon" />
          </div>
          <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>Lessons : 10</span>
            </div>

            <div className="icon__label">
              <PersonIcon className="icon" />
              <span>Students : +10</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>1 hour 30 min</span>
            </div>
          </div>
          <div className="button__whatsapp">
            <Link to="/Book/login">
              <button>Get Started</button>
            </Link>
            <WhatsAppIcon className="whatsappIcon" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
