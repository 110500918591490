import React, { useEffect } from "react";
import "./BookHeader.css";
import logo from "./hassan-documents/white-logo.png";
import { Link } from "react-router-dom";

function BookHeader() {
 
  
 
useEffect(()=>{
  const header = document.getElementById('header');
  const headerLogo = document.querySelector('#header img');
  if(window.location.href === "http://localhost:3000/book"){
    
    header.style.background="#252B34";
    headerLogo.style.width="60px";
    header.style.padding="10px 100px";
    header.style.transition = "background 0.3s ease-in-out";
    
   }
},[])
function scrollToTop(){
  window.scrollTo({top: 0, behavior: "smooth"});
}
  return (
    <div>
      <header id="header">
        <img src={logo} alt="" />
        <ul>
        <a href="/" onClick={scrollToTop}>
          <li>Home</li>
          </a>
          <a href="/#about">
          <li>About</li>
         </a>
          <a href="/#feedback">
          <li>Feedback</li>
          </a>
        </ul>
      </header>
    </div>
  );
}

export default BookHeader;
