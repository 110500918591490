import React, { useEffect } from "react";
import "./Hero.css";
import Header from "./Header";
import boy from "./boy.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";


function Hero() {
  useEffect(() => {
    AOS.init({
      once: true,
      // disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <div className="hero" id="home">
      <Header />

      <div className="hero__content">
        {/* <h3 data-aos="fade-down">TousTous Academy</h3> */}
        <h1 data-aos="fade-right">
          Your avenue to <span>exceptional</span> results.
        </h1>
        <div data-aos="zoom-in">Unlock Your Potential With Our Courses</div>
        <Link>
          <button data-aos="zoom-in-down" className="getStarted">Get started</button>
        </Link>
        <Link to='/login'>
          <button data-aos="zoom-in-left" className="loginBtn">Log in</button>
        </Link>
      </div>

      <div className="hero__bottom">
        <div className="title__description">
          <h1>
            01. 
            <span> Offline learning</span>
          </h1>
          <p>
            Benefit from face-to-face interactions with instructors and fellow
            students.
          </p>
        </div>
        <div className="title__description">
          <h1>
            02.
            <span> Hands-on expertise</span>
          </h1>
          <p>
            Acquire practical skill set through our real-world projects and case
            studies.
          </p>
        </div>
        <div className="title__description">
          <h1>
            03.
            <span> Career growth</span>
          </h1>
          <p>
            Access to a wide range of career opportunities upon completion of
            our courses.
          </p>
        </div>

        <iframe
          src="https://www.youtube.com/embed/zr24DDbd76A?si=kid27m2bwxT9pV41"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}

export default Hero;
