import React, { useEffect } from "react";
import Hero from "./Hero";
import About from "./About";
import StudentsOpinions from "./StudentsOpinions";
import Plan from "./Plan";
import Faq from "./Faq";
import Courses from "./Courses";
import Feedback from "./Feedback";
import WhyUs from "./WhyUs";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";

function Home() {
  const { hash } = useLocation();

  useEffect(() => {
      if (hash) {
          const element = document.getElementById(hash.replace('#', ''));
          if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
          }
      }
  }, [hash]);
  useEffect(() => {
   
    // Fetching data from API
    // fetch('https://jsonplaceholder.typicode.com/posts')
    //  .then(response => response.json())
    //  .then(data => console.log(data))
    //  .catch(error => console.error('Error:', error))
    //  .finally(() => console.log('Fetch complete'))

    localStorage.setItem("username", "toustous");
    localStorage.setItem("password", "123");
  }, []);
  return (
    <div>
      <Hero />
      <Courses />
      <Feedback />
      <WhyUs />
      {/* <About /> */}
      {/* <StudentsOpinions/> */}

      <Faq />
      <Footer />
    </div>
  );
}

export default Home;
