import logo from "./logo.svg";
import "./App.css";
import Hero from "./Hero";
import Header from "./Header";
import About from "./About";
import StudentsOpinions from "./StudentsOpinions";
import Plan from "./Plan";
import Faq from "./Faq";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import AllCourses from "./AllCourses";
import BookLogin from "./BookLogin";
import Book from "./Book";
import FreeVocabulary from "./FreeVocabulary";
import FreeIntroduction from "./FreeIntroduction";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact  element={<Home />} />
          <Route path="/about"  element={<About />} />
          <Route path="/login"   element={<Login />} />
          <Route path="/start"   element={<BookLogin />} />
          <Route path="/AllCourses"   element={<AllCourses />} />
          <Route path="/Book"   element={<Book />} />
          <Route path="/Book/login"   element={<BookLogin />} />
          <Route path="/free/vocabulary"   element={<FreeVocabulary />} />
          <Route path="free/introduction" element={<FreeIntroduction/>} />
        </Routes>
      </Router>
      {/* <Header/> */}
    </div>
  );
}

export default App;
