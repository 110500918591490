import React from "react";
import "./WhyUs.css";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import ClassIcon from "@mui/icons-material/Class";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import whyus1 from './document/whyus1.jpg'
import whyus2 from './document/whyus2.jpg'
import whyus3 from './document/whyus3.jpg'
import whyus4 from './document/whyus4.jpg'
function WhyUs() {
  return (
    <div className="whyUs">
      <div className="whyus__left">
        <h1>Why choose us?</h1>
        <p className="firstPara">
          Our English courses are designed to help you achieve fluency and
          confidence in speaking, writing, and understanding English.
        </p>
        <div className="whyus__benefit">
          <AccountBoxIcon className='whyus__icon' />
          <p>Achieve fluency and confidence in English.</p>
        </div>
        <div className="whyus__benefit">
          <CalendarMonthIcon className='whyus__icon' />
          <p>Study at your own pace with our flexible schedule.</p>
        </div>
        <div className="whyus__benefit">
          <LocalLibraryIcon className='whyus__icon' />
          <p>Flexible learning process to study at your pace</p>
        </div>
        <div className="whyus__benefit">
          <ClassIcon className='whyus__icon' />
          <p>
            Join thousands of people who have improved their English skills.
          </p>
        </div>     
        <div className="whyus__benefit">
          <AttachMoneyIcon className='whyus__icon' />
          <p>Top-quality education at an affordable price.</p>
        </div>
        <button className="enrollbtn">Enroll now</button>
      </div>
      <div className="whyus__right">
        <div>
          <img src={whyus1} alt="" />  
        </div>
        <div> <img src={whyus2} alt="" /></div>
        <div><img src={whyus3} alt="" /></div>
        <div> <img src={whyus4} alt="" /></div>
        
       
        
       
       
      </div>
    </div>
  );
}

export default WhyUs;
