import React,{ useEffect } from "react";
import logo from "./hassan-documents/bluelogo.png";
function FreeIntroduction() {
    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
      }, []);
      window.onscroll = function () {
        const header = document.getElementById("header");
        const headerLogo = document.querySelector("#header img");
    
        if (window.scrollY > 50) {
          // Change 30 to your desired scroll distance
    
          headerLogo.style.width = "60px";
          header.style.padding = "10px 100px";
          header.style.transition = "background 0.3s ease-in-out";
        } else {
          if (window.innerWidth < "668") {
            headerLogo.style.width = "60px";
            header.style.background = "";
            header.style.padding = "20px 100px";
          } else {
            headerLogo.style.width = "110px";
            header.style.background = "";
          }
        }
      };
      function scrollToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
  return <div>
    <header id="header">
        <img src={logo} alt="" />
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>
  </div>;
}

export default FreeIntroduction;
