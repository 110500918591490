import React, { useEffect, useState } from "react";
import "./FreeVocabulary.css";
import logo from "./hassan-documents/bluelogo.png";
import BookHeader from "./BookHeader";
function FreeVocabulary() {
  const [ex1q1, setex1q1] = useState("");
  const [ex1q2, setex1q2] = useState("");
  const [ex1q3, setex1q3] = useState("");
  const [ex1q4, setex1q4] = useState("");
  const [ex1q5, setex1q5] = useState("");
  const [ex1q6, setex1q6] = useState("");

  // // checkboxes
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  //  const [checkbox1,setCheckbox1] = useState(null);
  //  const [checkbox2,setCheckbox2] = useState(null);
  //  const [checkbox3,setCheckbox3] = useState(null);
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    const header = document.getElementById("header");
    const headerLogo = document.querySelector("#header img");

    // Change 30 to your desired scroll distance
    header.style.background = "#252B34";
    headerLogo.style.width = "60px";
    header.style.padding = "10px 100px";
    header.style.transition = "background 0.3s ease-in-out";
  }, []);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  // checkEx1
  const checkEx1 = () => {
    if (ex1q1.toLocaleLowerCase() === "confident") {
      document.querySelector(".ex1q1").style.border = "1px solid green";
    } else {
      document.querySelector(".ex1q1").style.border = "1px solid red";
    }
    if (ex1q2.toLocaleLowerCase() === "creativity") {
      document.querySelector(".ex1q2").style.border = "1px solid green";
    } else {
      document.querySelector(".ex1q2").style.border = "1px solid red";
    }
    if (ex1q3.toLocaleLowerCase() === "independence") {
      document.querySelector(".ex1q3").style.border = "1px solid green";
    } else {
      document.querySelector(".ex1q3").style.border = "1px solid red";
    }
    if (ex1q4.toLocaleLowerCase() === "enthusiastic") {
      document.querySelector(".ex1q4").style.border = "1px solid green";
    } else {
      document.querySelector(".ex1q4").style.border = "1px solid red";
    }
    if (ex1q5.toLocaleLowerCase() === "optimistic") {
      document.querySelector(".ex1q5").style.border = "1px solid green";
    } else {
      document.querySelector(".ex1q5").style.border = "1px solid red";
    }
    if (ex1q6.toLocaleLowerCase() === "competences") {
      document.querySelector(".ex1q6").style.border = "1px solid green";
    } else {
      document.querySelector(".ex1q6").style.border = "1px solid red";
    }
    console.log(ex1q1);
    console.log(ex1q2);
    console.log(ex1q3);
    console.log(ex1q4);
    console.log(ex1q5);
    console.log(ex1q6);
  };

  const clearEx1 = () => {
    setex1q1("");
    setex1q2("");
    setex1q3("");
    setex1q4("");
    setex1q5("");
    setex1q6("");
    document.querySelector(".ex1q1").textContent = "";
    document.querySelector(".ex1q2").textContent = "";
    document.querySelector(".ex1q3").textContent = "";
    document.querySelector(".ex1q4").textContent = "";
    document.querySelector(".ex1q5").textContent = "";
    document.querySelector(".ex1q6").textContent = "";
  };
  const showEx1 = () => {
    document.querySelector(".ex1q1").value = "confident";
    document.querySelector(".ex1q2").value = "creativity";
    document.querySelector(".ex1q3").value = "independence";
    document.querySelector(".ex1q4").value = "enthusiastic";
    document.querySelector(".ex1q5").value = "optimistic";
    document.querySelector(".ex1q6").value = "competences";
    document.querySelector(".ex1q1").style.color = "green";
    document.querySelector(".ex1q2").style.color = "green";
    document.querySelector(".ex1q3").style.color = "green";
    document.querySelector(".ex1q4").style.color = "green";
    document.querySelector(".ex1q5").style.color = "green";
    document.querySelector(".ex1q6").style.color = "green";
    setex1q1("confident");
    setex1q2("creativity");
    setex1q3("independence");
    setex1q4("enthusiastic");
    setex1q5("optimistic");
    setex1q6("competences");
  };

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const checkbox1 = document.querySelector("#checkbox1");
  const checkbox2 = document.querySelector("#checkbox2");
  const checkbox3 = document.querySelector("#checkbox3");

  

  const handleEx2 = () => {
    if (checkedIndex1 === 0) {
      console.log("Motivated is selected");
    }
    if (checkedIndex1 === 1) {
      console.log("Sociability is selected");
    }
    if (checkedIndex1 === 2) {
      console.log("Tolerance is selected");
    }
    if (checkedIndex2 === 3) {
      console.log("imaginative is selected");
    }
    if (checkedIndex2 === 4) {
      console.log("audacious is selected");
    }
    if (checkedIndex2 === 5) {
      console.log("flexible is selected");
    }

  };

  const [checkedIndex1, setCheckedIndex1] = useState(null);
  const handleCheckboxChange1 = (index) => {
    if (checkedIndex1 === index) {
      setCheckedIndex1(null); // Uncheck if the same checkbox is clicked
    } else {
      setCheckedIndex1(index); // Update to the new checkbox index
      // alert(`Checkbox ${index + 1} has been checked!`); // Alert on check
    }
  };

  const [checkedIndex2, setCheckedIndex2] = useState(null);
  const handleCheckboxChange2 = (index) => {
    if (checkedIndex2 === index) {
      setCheckedIndex2(null); // Uncheck if the same checkbox is clicked
    } else {
      setCheckedIndex2(index); // Update to the new checkbox index
      // alert(`Checkbox ${index + 1} has been checked!`); // Alert on check
    }
  };


  //
  const [checkedIndex3, setCheckedIndex3] = useState(null);
  const handleCheckboxChange3 = (index) => {
    if (checkedIndex3 === index) {
      setCheckedIndex3(null); // Uncheck if the same checkbox is clicked
    } else {
      setCheckedIndex3(index); // Update to the new checkbox index
      // alert(`Checkbox ${index + 1} has been checked!`); // Alert on check
    }
  };

  const [checkedIndex4, setCheckedIndex4] = useState(null);
  const handleCheckboxChange4 = (index) => {
    if (checkedIndex4 === index) {
      setCheckedIndex4(null); // Uncheck if the same checkbox is clicked
    } else {
      setCheckedIndex4(index); // Update to the new checkbox index
      // alert(`Checkbox ${index + 1} has been checked!`); // Alert on check
    }
  };


 
  const ShowEx2 = () => {
    document.querySelector(".motivated").style.border = "2px solid green";
    document.querySelector(".motivated").style.padding = "5px";
    document.querySelector(".audacious").style.border = "2px solid green";
    document.querySelector(".audacious").style.padding = "5px";
    document.querySelector(".bravery").style.border = "2px solid green";
    document.querySelector(".bravery").style.padding = "5px";
    document.querySelector(".adventure").style.border = "2px solid green";
    document.querySelector(".adventure").style.padding = "5px";
    
    
  };
  const ClearEx2 = () => {
    document.querySelectorAll(".option input").forEach(checks=>{
      checks.checked = false;
      checks.style.color = "black";

      
    })
    document.querySelectorAll(".option").forEach(checks=>{
      
      checks.style.border = "none";
      
      
    })
     
  }


  //

  const [B1,setB1] = useState("");
  const [D2,setD2] = useState("");
  const [E3,setE3] = useState("");
  const [C4,setC4] = useState("");
  const [A5,setA5] = useState("");

 
  const ShowEx3 = ()=>{
  document.querySelector(".ex3__answers__bottom").style.display="flex";
    


  }
 

 
 
  return (
    <div>
      <header id="header">
        <img src={logo} alt="" />
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>

      <div className="free__vocabulary__container">
        <div className="iframe">
          <iframe
            src="https://www.youtube.com/embed/zr24DDbd76A?si=kid27m2bwxT9pV41"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className="exercice1">
          <p>Exercise 1 : Choose the right answers from the options giving.</p>
          <ol>
            <li>
              {" "}
              1- Sarah is a very <b>(confidence)</b>{" "}
              <input
                type="text"
                className="ex1q1"
                onChange={(e) => setex1q1(e.target.value)}
                value={ex1q1}
              />{" "}
              person. She always believes in her abilities
            </li>
            <li>
              2- His <b>(creative)</b>{" "}
              <input
                type="text"
                className="ex1q2"
                onChange={(e) => setex1q2(e.target.value)}
                value={ex1q2}
              />{" "}
              helped him come up with an amazing solution for the project.
            </li>
            <li>
              3- They are known for their <b>(independent)</b>{" "}
              <input
                type="text"
                className="ex1q3"
                onChange={(e) => setex1q3(e.target.value)}
                value={ex1q3}
              />{" "}
              and prefer working on their own.
            </li>
            <li>
              4- She felt <b>(enthusiasm)</b>{" "}
              <input
                type="text"
                className="ex1q4"
                onChange={(e) => setex1q4(e.target.value)}
                value={ex1q4}
              />{" "}
              when she was accepted to her dream college.
            </li>
            <li>
              5- My brother is extremely <b>(optimism)</b>{" "}
              <input
                type="text"
                className="ex1q5"
                onChange={(e) => setex1q5(e.target.value)}
                value={ex1q5}
              />
              ; he always sees the good in every situation
            </li>
            <li>
              6- The company needs an employee with a lot of <b>(competent)</b>{" "}
              <input
                type="text"
                className="ex1q6"
                onChange={(e) => setex1q6(e.target.value)}
                value={ex1q6}
              />{" "}
              to manage this department.
            </li>
          </ol>
          <button onClick={() => checkEx1()}>Check</button>
          <button onClick={() => clearEx1()}>Clear</button>
          <button onClick={() => showEx1()}>Show</button>
        </div>
        <div className="exercice2">
          <p>Exercise 2 : Choose the right answers from the options giving.</p>
          <ol>
            <li>
              {" "}
              7. His strong _______ is what pushed him to
              start his own business.
            </li>
            <li className="checkbox__options">
              <div className="option motivated">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox1"
                  // checked={isChecked1}
                  // onChange={() => setIsChecked1(!isChecked1)}
                  checked={checkedIndex1 === 0}
                  onChange={() => handleCheckboxChange1(0)}
                />
                <span >Motivated</span>
              </div>
              <div className="option check1">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox2"
                  // checked={isChecked2}
                  // onChange={() => setIsChecked2(!isChecked2)}
                  checked={checkedIndex1 === 1}
                  onChange={() => handleCheckboxChange1(1)}
                />
                <span>Sociability</span>
              </div>
              <div className="option">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox3"
                  // checked={isChecked3}
                  // onChange={() => setIsChecked3(!isChecked3)}
                  checked={checkedIndex1 === 2}
                  onChange={() => handleCheckboxChange1(2)}
                />
                <span>Tolerance</span>
              </div>
            </li>
          </ol>
          <ol>
            <li>
              {" "}
              8. Mark is very ________; he is not afraid to speak in front of people.
            </li>
            <li className="checkbox__options">
              <div className="option">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox1"
                  // checked={isChecked1}
                  // onChange={() => setIsChecked1(!isChecked1)}
                  checked={checkedIndex2 === 3}
                  onChange={() => handleCheckboxChange2(3)}
                />
                <span>imaginative</span>
              </div>
              <div className="option check1 audacious">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox2"
                  // checked={isChecked2}
                  // onChange={() => setIsChecked2(!isChecked2)}
                  checked={checkedIndex2 === 4}
                  onChange={() => handleCheckboxChange2(4)}
                />
                <span>audacious</span>
              </div>
              <div className="option">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox3"
                  // checked={isChecked3}
                  // onChange={() => setIsChecked3(!isChecked3)}
                  checked={checkedIndex2 === 5}
                  onChange={() => handleCheckboxChange2(5)}
                />
                <span >Flexible</span>
              </div>
            </li>
          </ol>
          <ol>
            <li>
              {" "}
              9.  She handled the situation with great ________ and did not panic.

            </li>
            <li className="checkbox__options">
              <div className="option bravery">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox1"
                  // checked={isChecked1}
                  // onChange={() => setIsChecked1(!isChecked1)}
                  checked={checkedIndex3 === 6}
                  onChange={() => handleCheckboxChange3(6)}
                />
                <span>Bravery</span>
              </div>
              <div className="option check1">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox2"
                  // checked={isChecked2}
                  // onChange={() => setIsChecked2(!isChecked2)}
                  checked={checkedIndex3 === 7}
                  onChange={() => handleCheckboxChange3(7)}
                />
                <span className="audacious">Independence</span>
              </div>
              <div className="option">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox3"
                  // checked={isChecked3}
                  // onChange={() => setIsChecked3(!isChecked3)}
                  checked={checkedIndex3 === 8}
                  onChange={() => handleCheckboxChange3(8)}
                />
                <span >Talent</span>
              </div>
            </li>
          </ol>
          <ol>
            <li>
              {" "}
              10.  The ________ of the schedule allows us to work comfortably at our own pace.

            </li>
            <li className="checkbox__options">
              <div className="option adventure">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox1"
                  // checked={isChecked1}
                  // onChange={() => setIsChecked1(!isChecked1)}
                  checked={checkedIndex4 === 9}
                  onChange={() => handleCheckboxChange4(9)}
                />
                <span >adventure</span>
              </div>
              <div className="option check1">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox2"
                  // checked={isChecked2}
                  // onChange={() => setIsChecked2(!isChecked2)}
                  checked={checkedIndex4 === 10}
                  onChange={() => handleCheckboxChange4(10)}
                />
                <span>optimistic</span>
              </div>
              <div className="option">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox3"
                  // checked={isChecked3}
                  // onChange={() => setIsChecked3(!isChecked3)}
                  checked={checkedIndex4 === 11}
                  onChange={() => handleCheckboxChange4(11)}
                />
                <span>flexibility</span>
              </div>
            </li>
          </ol>
          <button onClick={() => ShowEx2()}>Check</button>
          <button onClick={() => ClearEx2()}>Clear</button>
          {/* <button onClick={() => ShowEx2()}>Show</button> */}
        </div>
        <div className="exercice3">
          <p>Exercise 3 : Match the following words with their collocations</p>
          <div className="exercice3__left__right">
            <div className="ex3__collocations">
            <ol>
              <li>Old</li>
              <li>Talented</li>
              <li>Strong</li>
              <li>Voice</li>
              <li>Realize</li>
            </ol>
           
            <ul>
              <li>A dream</li>
              <li>Fashioned</li>
              <li>An opinion</li>
              <li>Youth</li>
              <li>Headed</li>
            </ul>
            </div>
            <div className="ex3__answers__container">
              <div className="ex3__answers__top">
              <span>1 - <input type="text" className="1b"   /></span>
              <span>2 - <input type="text" className="2d" /></span>
              <span>3 - <input type="text" className="3e" /></span>
              <span>4 - <input type="text" className="4c" /></span>
              <span>5 - <input type="text" className="5a" /></span>
              </div>
              <div className="ex3__answers__bottom">
                <span>1 - B</span>
                <span>2 - D</span>
                <span>3 - E</span>
                <span>4 - C</span>
                <span>5 - A</span>
              </div>

            </div>


          </div>
          <button onClick={() => ShowEx3()}>Check</button>
          {/* <button onClick={() => ClearEx3()}>Clear</button> */}
        </div>
      </div>
    </div>
  );
}

export default FreeVocabulary;
