import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LanguageIcon from "@mui/icons-material/Language";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
function Footer() {
  return (
    <div className="footer" id="about">
      <div className="footer__container">
        <div className="footer__left">
          <h2>About Us</h2>
          <p>
            Hello, my name is Khalid Toustous. I am 26 years old and currently a
            university student studying English. Over the past three years, I
            have been teaching English, and I have also author second
            baccalaureate students.
          </p>
        </div>
        <div className="footer__center">
          <h2>Privacy Police</h2>
          <Link className="privacy" to="">
            <div>Privacy Police</div>
          </Link>
          <Link className="terms"  to="">
            <div>Terms and Conditions</div>
          </Link>
        </div>
        <div className="footer__right">
          <h2>Contact us</h2>
          <div className="social__icons">
            <PersonIcon className="footer__icon" />
            <MailIcon className="footer__icon" />
            <WhatsAppIcon className="footer__icon" />
          </div>
          <h2>Follow us</h2>
          <div className="social__icons">
            <LanguageIcon className="footer__icon" />
            <InstagramIcon className="footer__icon" />
            <YouTubeIcon className="footer__icon" />
            <FacebookIcon className="footer__icon" />
          </div>
        </div>
      </div>

      <p className="copyright">&copy; 2022 Toustous. All rights reserved.</p>
    </div>
  );
}

export default Footer;
